.Plans-container{
    background-color: rgb(89, 81, 81);
    margin-top: -1rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.Programs_header{
    display: flex;
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    font-style: italic;
    
}
.Plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.Plan{
    display: flex;
    flex-direction: column;
    background-color: grey;
    color: white;
    gap: 1.5rem;
    padding: 1.5rem;
    width: 15rem;
}
.Plan:nth-child(2){
    background-color: orangered;
    transform: scale(1.1);
}
.Plan>svg{
    fill: orange;
    width: 2rem;
    height: 2rem;
}
.Plan>span:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
    
}
.Plan>span:nth-child(3){
    font-size: 2.5rem;
    font-weight: bold;
}
.features{
  display: flex;
  flex-direction: column;
  gap: 1rem;  
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.Plans>:nth-child(2)>svg{
    fill: white;
}
.Plans>:nth-child(2)>button{
    color: orange;
}
/*.Plans-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}
.Plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}*/
@media screen and (max-width:768px) {
    .Plans{
       flex-direction: column;

    }
    .Programs_header{
        margin-top: -6rem;
        flex-direction: column;
        grid-template: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    .Plan:nth-child(2){
        transform: none;
    }
}