.Reason{
    background-color: rgb(89, 81, 81);
    display: flex;
    padding: 0 2rem;
    gap: 2rem;
}
.left-r{
    margin-top: 3rem;
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}
.left-r>:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-r>:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}
.left-r>:nth-child(4){
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;
}
.right-r{
    margin-top: 3rem;
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-r>span{
     font-weight: bold;
     color: orange;
}
.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.Detail{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.Detail>div{
    display: flex;
    font-size: 1rem;
    text-transform: uppercase;
    gap: 1rem;
}
.Detail>div>img{
    width: 2rem;
    height: 2rem;
}
.Ours{
    color: white;
    font-size: 1rem;
}
.Partner{
    display: flex;
    gap: 1rem;
}
.Partner>img{
    width: 2.5rem;
}
@media screen and (max-width:768px){
    .Reason{
        flex-direction: column;
    }
    .left-r{
        overflow: hidden;
        grid-auto-rows: auto;
    }
    .left-r>:nth-child(1){
        width: 4rem;
        height: 17rem;
    }
    .left-r>:nth-child(2){
        width: 10rem;
        height: 11rem;
    }
    .left-r>:nth-child(3){
        width: 4.5rem;
        height: 6rem;
    }
    .left-r>:nth-child(4){
        width: 4.5rem;
        height: 6rem;
    }
    .Partner>img{
    flex-direction: column;
    height:auto;
    width: auto;
    margin-bottom: 8rem;
    }
}