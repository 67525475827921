
  .Gym{
    mix-blend-mode: overlay;
    display: flex;
    flex-direction: column;
    gap: 6rem;
    overflow: hidden;
  }
  ::-webkit-scrollbar{
    display: none;
  }
 .stroke-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}
.bt{
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 3px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
} 
.bt:hover{
  cursor:pointer;
}
/*.blur{
  background: rgba(249, 103, 18, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
}*/